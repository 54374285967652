<template>
  <!-- <HelloWorld /> -->
   <ImageToImage />
 <!-- <PromptToImage /> -->
</template>

<script>
//import PromptToImage from './components/PromptToImage.vue';

 import ImageToImage from './components/ImageToImage.vue';
// import HelloWorld from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {
    // PromptToImage
    ImageToImage
}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
